import { Theme } from '@material-ui/core/styles';
import palette from 'theme/palette';

// ----------------------------------------------------------------------

export default function IconButton(theme: Theme) {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: palette.light.secondary
        }
      }
    }
  };
}
