// material
import { Box, BoxProps } from '@material-ui/core';

// ----------------------------------------------------------------------

export default function GoogleIcon({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 512 512"
      >
        <path
          fill="#167ee6"
          d="M472.4 213.9H281.9c-8.4 0-15.2 6.8-15.2 15.2V290c0 8.4 6.8 15.2 15.2 15.2h107.3c-11.7 30.5-33.7 56-61.6 72.2l45.7 79.2C446.6 414.2 490 339.7 490 256.4c0-11.9-.9-20.4-2.6-29.9-1.4-7.3-7.7-12.6-15-12.6z"
        ></path>
        <path
          fill="#12b347"
          d="M256.5 396.6c-52.5 0-98.3-28.7-122.9-71.1l-79.2 45.6C94.7 441 170.2 488 256.5 488c42.4 0 82.3-11.4 116.8-31.3v-.1l-45.7-79.2c-21 12.2-45.2 19.2-71.1 19.2z"
        ></path>
        <path
          fill="#0f993e"
          d="M373.2 456.7v-.1l-45.7-79.2c-20.9 12.1-45.1 19.2-71 19.2V488c42.4 0 82.3-11.4 116.7-31.3z"
        ></path>
        <path
          fill="#ffd500"
          d="M114.4 254.5c0-25.9 7.1-50.1 19.2-71l-79.2-45.6C34.4 172.2 23 212 23 254.5s11.4 82.3 31.4 116.6l79.2-45.6c-12.2-20.9-19.2-45.1-19.2-71z"
        ></path>
        <path
          fill="#ff4b26"
          d="M256.5 112.4c34.2 0 65.7 12.2 90.2 32.4 6.1 5 14.9 4.6 20.4-.9l43.1-43.1c6.3-6.3 5.8-16.6-.9-22.4C368.2 42.6 314.7 21 256.5 21 170.2 21 94.7 68 54.4 137.9l79.2 45.6c24.6-42.4 70.4-71.1 122.9-71.1z"
        ></path>
        <path
          fill="#d93f21"
          d="M346.7 144.8c6.1 5 14.9 4.6 20.4-.9l43.1-43.1c6.3-6.3 5.8-16.6-.9-22.4C368.2 42.6 314.7 21 256.5 21v91.4c34.2 0 65.7 12.1 90.2 32.4z"
        ></path>
      </svg>
    </Box>
  );
}
