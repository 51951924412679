import { createSlice } from '@reduxjs/toolkit';
import { sum, map, filter, uniqBy } from 'lodash';
import { store } from '../store';
// utils
import { DataStore, Predicates } from 'aws-amplify';
import { ClientState } from '../../@types/client';
import { Client } from '../../models';

// ----------------------------------------------------------------------

const initialState: ClientState = {
  isLoading: false,
  error: false,
  clients: [],
  client: null,
  sortBy: null,
  filters: {
    name: '',
    industry: '',
    office: [],
    country: []
  }
};

const slice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET clientS
    getClientsSuccess(state, action) {
      state.isLoading = false;
      state.clients = action.payload;
    },

    // GET client
    getClientSuccess(state, action) {
      state.isLoading = false;
      state.client = action.payload;
    },

    //  SORT & FILTER clientS
    sortByClients(state, action) {
      state.sortBy = action.payload;
    },

    filterClients(state, action) {
      state.filters.name = action.payload.name;
      state.filters.industry = action.payload.industry;
      state.filters.office = action.payload.office;
      state.filters.country = action.payload.country;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByClients, filterClients } = slice.actions;

export function getClients() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const clients: Client[] = await DataStore.query(Client, Predicates.ALL);
      dispatch(slice.actions.getClientsSuccess(clients));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getClient(id: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const client: Client[] = await DataStore.query(Client, (c) =>
        c.id('eq', id)
      );
      dispatch(slice.actions.getClientSuccess(client[0]));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
