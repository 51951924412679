// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Responsibility = {
  "PERSONAL": "PERSONAL",
  "COMPANY": "COMPANY"
};

const Phase = {
  "I": "I",
  "II": "II",
  "III": "III",
  "IV": "IV",
  "V": "V",
  "VI": "VI",
  "VII": "VII",
  "VIII": "VIII",
  "IX": "IX",
  "X": "X",
  "XI": "XI"
};

const Country = {
  "MEXICO": "MEXICO",
  "RUSSIA": "RUSSIA",
  "USA": "USA",
  "UKRAINE": "UKRAINE"
};

const ActivityType = {
  "PHASE": "Phase",
  "POST_V": "PostV",
  "COACHING": "Coaching",
  "SYNER_CORE": "SynerCore"
};

const OfficeLocation = {
  "CALIFORNIA": "CALIFORNIA",
  "RUSSIA": "RUSSIA",
  "GUADALAJARA": "GUADALAJARA",
  "CDMX": "CDMX",
  "MONTERREY": "MONTERREY"
};

const { Task, Company, Activity, Office, Client } = initSchema(schema);

export {
  Task,
  Company,
  Activity,
  Office,
  Client,
  Responsibility,
  Phase,
  Country,
  ActivityType,
  OfficeLocation
};