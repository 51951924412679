// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: '100%', height: '100%' }}
  />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking')
};

const sidebarConfig = [
  {
    subheader: 'management',
    roles: ['admin'],
    items: [
      {
        title: 'offices',
        path: PATH_DASHBOARD.office.list,
        icon: ICONS.ecommerce
      }
    ]
  },
  {
    subheader: 'companies',
    roles: ['user', 'admin'],
    items: [
      {
        title: 'client',
        path: PATH_DASHBOARD.client.list,
        icon: ICONS.user
      },
      {
        title: 'companies',
        path: PATH_DASHBOARD.company.list,
        icon: ICONS.banking
      },
      {
        title: 'activities',
        path: PATH_DASHBOARD.activity.list,
        icon: ICONS.booking
      },
      {
        title: 'task',
        path: PATH_DASHBOARD.task.list,
        icon: ICONS.kanban
      }
    ]
  }
];

export default sidebarConfig;
