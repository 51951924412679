import { createSlice } from '@reduxjs/toolkit';
import { sum, map, filter, uniqBy } from 'lodash';
import { store } from '../store';
// utils
import { DataStore, Predicates } from 'aws-amplify';
import { OfficeState } from '../../@types/office';
import { Office } from '../../models';

// ----------------------------------------------------------------------

const initialState: OfficeState = {
  isLoading: false,
  error: false,
  offices: [],
  office: null,
  sortBy: null,
  filters: {
    name: ''
  }
};

const slice = createSlice({
  name: 'office',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET officeS
    getOfficesSuccess(state, action) {
      state.isLoading = false;
      state.offices = action.payload;
    },

    // GET office
    getOfficeSuccess(state, action) {
      state.isLoading = false;
      state.office = action.payload;
    },

    //  SORT & FILTER officeS
    sortByOffices(state, action) {
      state.sortBy = action.payload;
    },

    filterOffices(state, action) {
      state.filters.name = action.payload.name;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByOffices, filterOffices } = slice.actions;

export function getOffices() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const offices: Office[] = await DataStore.query(Office, Predicates.ALL);
      dispatch(slice.actions.getOfficesSuccess(offices));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getOffice(id: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const office: Office[] = await DataStore.query(Office, (c) =>
        c.id('eq', id)
      );
      dispatch(slice.actions.getOfficeSuccess(office[0]));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
