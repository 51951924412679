import { createSlice } from '@reduxjs/toolkit';
import { sum, map, filter, uniqBy } from 'lodash';
import { store } from '../store';
// utils
import { DataStore, Predicates } from 'aws-amplify';
import { ActivityState } from '../../@types/activity';
import { Activity, Phase } from '../../models';

// ----------------------------------------------------------------------

const initialState: ActivityState = {
  isLoading: false,
  error: false,
  activities: [],
  activity: null,
  sortBy: null,
  filters: {
    phase: '',
    associate: ''
  }
};

const slice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET activities
    getActivitiesSuccess(state, action) {
      state.isLoading = false;
      state.activities = action.payload;
    },

    // GET activity
    getActivitySuccess(state, action) {
      state.isLoading = false;
      state.activity = action.payload;
    },

    //  SORT & FILTER activities
    sortByActivities(state, action) {
      state.sortBy = action.payload;
    },

    filterActivities(state, action) {
      state.filters.phase = action.payload.phase;
      state.filters.associate = action.payload.associate;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByActivities, filterActivities } = slice.actions;

export function getActivities(companyID?: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      // If we receive a companyID we search for their activities
      const activities: Activity[] = await DataStore.query(
        Activity,
        companyID ? (a) => a.companyID('eq', companyID) : Predicates.ALL
      );
      dispatch(slice.actions.getActivitiesSuccess(activities));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getActivity(id: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const activity: Activity[] = await DataStore.query(Activity, (c) =>
        c.id('eq', id)
      );
      dispatch(slice.actions.getActivitySuccess(activity[0]));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
