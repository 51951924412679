import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleGuard from '../guards/RoleGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/dashboard', element: <GeneralApp /> },
        {
          path: 'office',
          children: [
            {
              path: 'list',
              element: (
                <RoleGuard accessibleRoles={['admin']}>
                  <OfficeList />
                </RoleGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleGuard accessibleRoles={['admin']}>
                  <OfficeCreate />
                </RoleGuard>
              )
            },
            {
              path: 'edit/:id',
              element: (
                <RoleGuard accessibleRoles={['admin']}>
                  <OfficeCreate />
                </RoleGuard>
              )
            }
          ]
        },
        {
          path: 'client',
          children: [
            { path: 'list', element: <ClientList /> },
            { path: 'new', element: <ClientCreate /> },
            { path: 'edit/:id', element: <ClientCreate /> }
          ]
        },
        {
          path: 'company',
          children: [
            { path: 'list', element: <CompanyList /> },
            { path: 'new', element: <CompanyCreate /> },
            { path: 'edit/:id', element: <CompanyCreate /> }
          ]
        },
        {
          path: 'activity',
          children: [
            { path: 'list', element: <ActivityList /> },
            { path: 'new', element: <ActivityCreate /> },
            { path: 'edit/:id', element: <ActivityCreate /> }
          ]
        },
        {
          path: 'user',
          children: [{ path: 'account', element: <UserAccount /> }]
        },
        {
          path: 'task',
          children: [{ path: 'list', element: <Kanban /> }]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [{ path: '/', element: <Navigate to="/auth/login" replace /> }]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS
const GeneralApp = Loadable(
  lazy(() => import('../pages/dashboard/GeneralApp'))
);

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(
  lazy(() => import('../pages/authentication/Register'))
);
const ResetPassword = Loadable(
  lazy(() => import('../pages/authentication/ResetPassword'))
);
const VerifyCode = Loadable(
  lazy(() => import('../pages/authentication/VerifyCode'))
);
// Dashboard
// User
const UserAccount = Loadable(
  lazy(() => import('../pages/dashboard/UserAccount'))
);
// Office
const OfficeList = Loadable(
  lazy(() => import('../pages/dashboard/OfficeList'))
);
const OfficeCreate = Loadable(
  lazy(() => import('../pages/dashboard/OfficeCreate'))
);
// Client
const ClientList = Loadable(
  lazy(() => import('../pages/dashboard/ClientList'))
);
const ClientCreate = Loadable(
  lazy(() => import('../pages/dashboard/ClientCreate'))
);
// Company
const CompanyList = Loadable(
  lazy(() => import('../pages/dashboard/CompanyList'))
);
const CompanyCreate = Loadable(
  lazy(() => import('../pages/dashboard/CompanyCreate'))
);
// Activity
const ActivityList = Loadable(
  lazy(() => import('../pages/dashboard/ActivityList'))
);
const ActivityCreate = Loadable(
  lazy(() => import('../pages/dashboard/ActivityCreate'))
);
// Task
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
// Main
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
