// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  task: {
    root: path(ROOTS_DASHBOARD, '/task'),
    list: path(ROOTS_DASHBOARD, '/task/list'),
    newTask: path(ROOTS_DASHBOARD, '/task/new'),
    editById: (id: string) => path(ROOTS_DASHBOARD, `/task/edit/${id}`)
  },
  office: {
    root: path(ROOTS_DASHBOARD, '/office'),
    list: path(ROOTS_DASHBOARD, '/office/list'),
    newOffice: path(ROOTS_DASHBOARD, '/office/new'),
    editById: (id: string) => path(ROOTS_DASHBOARD, `/office/edit/${id}`)
  },
  company: {
    root: path(ROOTS_DASHBOARD, '/company'),
    list: path(ROOTS_DASHBOARD, '/company/list'),
    newCompany: path(ROOTS_DASHBOARD, '/company/new'),
    editById: (id: string) => path(ROOTS_DASHBOARD, `/company/edit/${id}`)
  },
  client: {
    root: path(ROOTS_DASHBOARD, '/client'),
    list: path(ROOTS_DASHBOARD, '/client/list'),
    newClient: path(ROOTS_DASHBOARD, '/client/new'),
    editById: (id: string) => path(ROOTS_DASHBOARD, `/client/edit/${id}`)
  },
  activity: {
    root: path(ROOTS_DASHBOARD, '/activity'),
    list: path(ROOTS_DASHBOARD, '/activity/list'),
    newActivity: path(ROOTS_DASHBOARD, '/activity/new'),
    editById: (id: string) => path(ROOTS_DASHBOARD, `/activity/edit/${id}`)
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  }
};
