import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import clientReducer from './slices/client';
import companyReducer from './slices/company';
import officeReducer from './slices/office';
import userReducer from './slices/user';
import activityReducer from './slices/activity';
import kanbanReducer from './slices/kanban';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const clientPersistConfig = {
  key: 'client',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy']
};

const rootReducer = combineReducers({
  office: officeReducer,
  company: companyReducer,
  activity: activityReducer,
  user: userReducer,
  kanban: kanbanReducer,
  client: persistReducer(clientPersistConfig, clientReducer)
});

export { rootPersistConfig, rootReducer };
