import { createSlice } from '@reduxjs/toolkit';
import { sum, map, filter, uniqBy } from 'lodash';
import { store } from '../store';
// utils
import { DataStore, Predicates } from 'aws-amplify';
import { CompanyState } from '../../@types/company';
import { Company } from '../../models';

// ----------------------------------------------------------------------

const initialState: CompanyState = {
  isLoading: false,
  error: false,
  companies: [],
  company: null,
  sortBy: null,
  filters: {
    name: '',
    industry: ''
  }
};

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET companies
    getCompaniesSuccess(state, action) {
      state.isLoading = false;
      state.companies = action.payload;
    },

    // GET company
    getCompanySuccess(state, action) {
      state.isLoading = false;
      state.company = action.payload;
    },

    //  SORT & FILTER companies
    sortByCompanies(state, action) {
      state.sortBy = action.payload;
    },

    filterCompanies(state, action) {
      state.filters.name = action.payload.name;
      state.filters.industry = action.payload.industry;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByCompanies, filterCompanies } = slice.actions;

export function getCompanies() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const companies: Company[] = await DataStore.query(
        Company,
        Predicates.ALL
      );
      dispatch(slice.actions.getCompaniesSuccess(companies));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCompany(id: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const company: Company[] = await DataStore.query(Company, (c) =>
        c.id('eq', id)
      );
      dispatch(slice.actions.getCompanySuccess(company[0]));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
